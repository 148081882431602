import { Flex, Spacer, Switch, Text, Tooltip } from '@chakra-ui/react'
import {
  IoIosNavigate,
  IoIosPeople,
  IoMdAlert,
  IoMdArrowRoundUp,
  IoMdExpand,
  IoMdEye,
  IoMdImages,
  IoMdLink,
  IoMdPin,
  IoMdPricetags,
  IoMdSearch,
  IoMdTimer,
} from 'react-icons/io'
import {
  useGetBrandNamesWithOrgQuery,
  useGetPlatformSubtypesByProductQuery,
  useGetTagsQuery,
  useGetUsersQuery,
  useGetAllExternalUsersQuery,
  useGetRulesQuery,
  Order_By,
} from '../../generated/graphql'
import { getOrgData, useIsEmployeeView, useOrgID } from '../../hooks/id_token_claims'
import {
  addFilter,
  containsFilterByType,
  genReportFilter,
  removeFilterByType,
} from '../../hooks/report_table_filters'
import {
  useSelectedReportType,
  useSelectedReportStatus,
  useSelectedReportFilters,
} from '../../pages/reports'
import {
  DarkWebNetwork,
  DarkWebSource,
  ReportFilterType,
  ReportType,
  Severity,
} from '../../utils/constants'
import {
  darkWebSortingMechanismToDisplay,
  getSpoofSortTypes,
  sortingMechanismToDisplay,
} from '../../utils/reports/report_sorting_mechanism'
import {
  PLATFORM_TYPE_DISPLAY_MAP,
  REPORT_TYPES_WITH_PLATFORMS,
} from '../../utils/reports/report_utils'
import SearchFilterToken from '../collection_reports_table/search_filter_token'
import SortDropdown from '../sort_dropdown'
import {
  ReportFilterDropdown,
  SHOW_ALL_FILTER_VALUE,
  useSelectedContext,
} from '../tables/filter_dropdown'
import { Classification } from '../../utils/constants'
import DateFilterDropdown from '../collection_reports_table/date_filter_dropdown/date_filter_dropdown'
import { IoCubeSharp, IoPerson } from 'react-icons/io5'
import {
  AlertAgeStatus,
  ReportSourceCategory,
  ReportSourceCategoryLabels,
  ReportStatus,
} from '@/generated/enums'
import { formatSnakeStringWithCapitalization } from '../../utils/string_utils'
import { FEATURES_TO_RENDER } from '@/components/web2/detection_reasons/detection_reasons_table'
import {
  getPlatformSubtypeDisplayValue,
  PLATFORM_SUBTYPE_OTHER_DISPLAY_VALUE,
} from '@/utils/reports/platform_subtype_utils'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelectedPage } from '@/hooks/spoof_report_table_filters'
import { getSortedAlertAssigneesWithUnassigned } from '@/utils/enforcement_utils'
import { DOPPEL_INTERNAL_PURPLE } from '@/utils/style'

const FILTER_WIDTH_HORIZ_PX = 135
const FILTER_WIDTH_VERT_PX = 180

const filterValueToDisplayWithUnverified = (value: string) => {
  const modifiedValue =
    value === Classification.UNKNOWN.toString() ? 'unverified' : value
  return formatSnakeStringWithCapitalization(modifiedValue)
}

const getBrandDisplayValue = (value, brandsData, isOrgUnifiedView) => {
  if (value === ReportFilterType.Brand) {
    return ReportFilterType.Brand
  } else if (value === SHOW_ALL_FILTER_VALUE) {
    return SHOW_ALL_FILTER_VALUE
  } else if (!brandsData) {
    return ''
  }
  const brand = brandsData.spoofing_entities.find((entity) => entity.id === value)
  return isOrgUnifiedView
    ? `${brand.entity_name} (${brand.organization.name})`
    : brand.entity_name
}

const getTagDisplayValue = (value, isOrgUnifiedView) => {
  if (value === ReportFilterType.Tag) {
    return isOrgUnifiedView ? 'Global Tag' : 'Tag'
  }
  return value
}

const getSourceCategoryDisplayValue = (category: ReportSourceCategory) => {
  const label =
    ReportSourceCategoryLabels[category] ??
    formatSnakeStringWithCapitalization(category)
  return label
}

const showPlatformSubtypeFilter = (product: ReportType) => {
  if (product === ReportType.DARK_WEB) return false // using subtabs instead
  return REPORT_TYPES_WITH_PLATFORMS.includes(product)
}

const ReportFilterBarDropdown = ({
  style,
  isDateFilter = false,
  widthOverridePx,
  ...props
}: any) => {
  const [, setSelectedPage] = useSelectedPage()
  const Component = isDateFilter ? DateFilterDropdown : ReportFilterDropdown
  const isVertical = style === FilterBarStyle.VERTICAL
  const horizWidth = widthOverridePx || FILTER_WIDTH_HORIZ_PX
  const vertWidth = FILTER_WIDTH_VERT_PX
  const width = isVertical ? vertWidth : horizWidth
  return (
    <Component
      marginRight="8px"
      maxDisplayLength={isVertical ? 20 : 10}
      onSelect={() => setSelectedPage(1)}
      textMaxWidth={`${width - 25}px`}
      w={`${width}px`}
      {...(isVertical && { marginBottom: 2 })}
      {...props}
    />
  )
}

export const ReportFilterBarSwitch = ({
  label,
  tooltipText,
  filterType,
  isInternal,
  ...props
}: any) => {
  const [, setSelectedPage] = useSelectedPage()
  const [selectedFilters, setSelectedFilters] = useSelectedContext(null)
  const [isChecked, setIsChecked] = useState(
    containsFilterByType(ReportFilterType.LinkedAlerts, selectedFilters),
  )

  useEffect(() => {
    if (isChecked) {
      setSelectedFilters(addFilter(filterType, 'true', selectedFilters))
    } else {
      setSelectedFilters(removeFilterByType(filterType, selectedFilters))
    }
  }, [isChecked])

  return (
    <Tooltip fontSize={12} fontWeight={500} label={tooltipText} maxW={'250px'}>
      <Flex
        alignItems={'center'}
        bg={isInternal ? DOPPEL_INTERNAL_PURPLE : undefined}
        flexDir={'row'}
        width={`170px`}
        {...props}
      >
        <Switch
          isChecked={isChecked}
          onChange={(e) => {
            setIsChecked(e.target.checked)
            setSelectedPage(1)
          }}
          pl={2}
        />

        <Text fontSize={12} fontWeight={500} marginLeft={2} noOfLines={1}>
          {label}
        </Text>
      </Flex>
    </Tooltip>
  )
}

const generateFilterComponentsMap = ({
  style,
  brandOptions,
  spoofStatusFilterOptions,
  severityFilterOptions,
  sourceFilterOptions,
  uploaderFilterOptions,
  tagFilterOptions,
  tldFilterOptions,
  platformSubtypeFilterOptions,
  darkWebNetworkFilterOptions,
  darkWebSourceFilterOptions,
  detectionReasonFilterOptions,
  detectionRuleFilterOptions,
  detectionRuleDescriptions,
  isAgedFilterOptions,
  brandsData,
  platformSubtypesIdToDisplay,
  assigneeFilterOptions,
  assigneeIdToName,
  isOrgUnifiedView,
}) => ({
  [ReportFilterType.DateRange]: (
    <ReportFilterBarDropdown isDateFilter={true} style={style} />
  ),

  [ReportFilterType.Brand]: (
    <ReportFilterBarDropdown
      alphabetizeFilterOptions={true}
      disabled={!brandOptions?.length}
      dropdownIcon={<IoMdImages size="18" />}
      filterOptions={brandOptions}
      filterType={ReportFilterType.Brand}
      isMultiSelect={true}
      showSearchBar={true}
      style={style}
      valueToDisplayFunction={(value) =>
        getBrandDisplayValue(value, brandsData, isOrgUnifiedView)
      }
    />
  ),

  [ReportFilterType.Classification]: (
    <ReportFilterBarDropdown
      dropdownIcon={<IoMdExpand size="18" />}
      filterOptions={spoofStatusFilterOptions}
      filterType={ReportFilterType.Classification}
      isMultiSelect={true}
      style={style}
      valueToDisplayFunction={filterValueToDisplayWithUnverified}
    />
  ),

  [ReportFilterType.Severity]: (
    <ReportFilterBarDropdown
      dropdownIcon={<IoMdAlert size="18" />}
      filterOptions={severityFilterOptions}
      filterType={ReportFilterType.Severity}
      isMultiSelect={true}
      style={style}
      valueToDisplayFunction={formatSnakeStringWithCapitalization}
    />
  ),

  [ReportFilterType.Source]: (
    <ReportFilterBarDropdown
      dropdownIcon={<IoMdPin size="18" />}
      filterOptions={sourceFilterOptions}
      filterType={ReportFilterType.Source}
      isMultiSelect={true}
      showSearchBar={true}
      style={style}
      valueToDisplayFunction={getSourceCategoryDisplayValue}
    />
  ),

  [ReportFilterType.Uploader]: (
    <ReportFilterBarDropdown
      disabled={!uploaderFilterOptions?.length}
      dropdownIcon={<IoMdArrowRoundUp size="18" />}
      filterOptions={uploaderFilterOptions}
      filterType={ReportFilterType.Uploader}
      isMultiSelect={true}
      showSearchBar={true}
      style={style}
    />
  ),

  [ReportFilterType.Tag]: (
    <ReportFilterBarDropdown
      dropdownIcon={<IoMdPricetags size="18" />}
      filterOptions={tagFilterOptions}
      filterType={ReportFilterType.Tag}
      isMultiSelect={true}
      showSearchBar={true}
      style={style}
      valueToDisplayFunction={(value) => getTagDisplayValue(value, isOrgUnifiedView)}
    />
  ),

  [ReportFilterType.TLD]: (
    <ReportFilterBarDropdown
      dropdownIcon={<IoMdLink size="18" />}
      filterOptions={tldFilterOptions}
      filterType={ReportFilterType.TLD}
      isMultiSelect={true}
      style={style}
      valueToDisplayFunction={formatSnakeStringWithCapitalization}
    />
  ),

  [ReportFilterType.PlatformSubtype]: (
    <ReportFilterBarDropdown
      dropdownIcon={<IoCubeSharp size="18" />}
      filterOptions={platformSubtypeFilterOptions}
      filterType={ReportFilterType.PlatformSubtype}
      isMultiSelect={true}
      showSearchBar={true}
      style={style}
      valueToDisplayFunction={(value) => platformSubtypesIdToDisplay[value] ?? value}
    />
  ),

  [ReportFilterType.DarkWebNetwork]: (
    <ReportFilterBarDropdown
      dropdownIcon={<IoIosPeople size="18" />}
      filterOptions={darkWebNetworkFilterOptions}
      filterType={ReportFilterType.DarkWebNetwork}
      style={style}
      valueToDisplayFunction={formatSnakeStringWithCapitalization}
    />
  ),

  [ReportFilterType.DarkWebSource]: (
    <ReportFilterBarDropdown
      dropdownIcon={<IoIosNavigate size="18" />}
      filterOptions={darkWebSourceFilterOptions}
      filterType={ReportFilterType.DarkWebSource}
      style={style}
      valueToDisplayFunction={formatSnakeStringWithCapitalization}
    />
  ),

  [ReportFilterType.DetectionReason]: (
    <ReportFilterBarDropdown
      dropdownIcon={<IoMdEye size="18" />}
      filterOptions={detectionReasonFilterOptions}
      filterType={ReportFilterType.DetectionReason}
      isInternal={true}
      isMultiSelect={true}
      showSearchBar={true}
      style={style}
      valueToDisplayFunction={formatSnakeStringWithCapitalization}
    />
  ),

  [ReportFilterType.DetectionRule]: (
    <ReportFilterBarDropdown
      dropdownIcon={<IoMdEye size="18" />}
      filterOptions={detectionRuleFilterOptions}
      filterType={
        isOrgUnifiedView
          ? `Global${ReportFilterType.DetectionRule}`
          : ReportFilterType.DetectionRule
      }
      isInternal={true}
      isMultiSelect={true}
      showSearchBar={true}
      style={style}
      valueToDisplayFunction={(value) => detectionRuleDescriptions[value] ?? value}
    />
  ),

  [ReportFilterType.IsAged]: (
    <ReportFilterBarDropdown
      dropdownIcon={<IoMdTimer size="18" />}
      filterOptions={isAgedFilterOptions}
      filterType={ReportFilterType.IsAged}
      isInternal={true}
      isMultiSelect={false}
      style={style}
      valueToDisplayFunction={formatSnakeStringWithCapitalization}
      widthOverridePx={120}
    />
  ),
  [ReportFilterType.Assignee]: (
    <ReportFilterBarDropdown
      dropdownIcon={<IoPerson size="18" />}
      filterOptions={assigneeFilterOptions}
      filterType={ReportFilterType.Assignee}
      isInternal={true}
      isMultiSelect={true}
      showSearchBar={true}
      style={style}
      valueToDisplayFunction={(value) => assigneeIdToName[value] ?? value}
      widthOverridePx={120}
    />
  ),
  [ReportFilterType.LinkedAlerts]: (
    <ReportFilterBarSwitch
      filterType={ReportFilterType.LinkedAlerts}
      label="With Linked Alerts"
      tooltipText="Only show alerts that have been linked to other alerts."
    />
  ),
  [ReportFilterType.NoEnforcementRequest]: (
    <ReportFilterBarSwitch
      borderRadius={4}
      filterType={ReportFilterType.NoEnforcementRequest}
      isInternal={true}
      label="No Enforcement Requests"
      marginRight="8px"
      tooltipText="Show alerts where no enforcement requests have been submitted."
      width="212px"
    />
  ),
})

const getFiltersForReportType = (
  reportType: ReportType,
  isEmployeeView: boolean,
  selectedReportStatus: ReportStatus,
) => {
  if (reportType === ReportType.SUSPICIOUS_EMAILS) {
    return [ReportFilterType.DateRange, ReportFilterType.Tag]
  }
  const visibleFilters: ReportFilterType[] = [
    ReportFilterType.DateRange,
    ReportFilterType.Brand,
    ReportFilterType.Classification,
    ReportFilterType.Severity,
    ReportFilterType.Source,
    ReportFilterType.Uploader,
    ReportFilterType.Tag,
  ] // all universal

  if (showPlatformSubtypeFilter(reportType)) {
    visibleFilters.push(ReportFilterType.PlatformSubtype)
  }

  if (reportType === ReportType.DOMAINS) {
    visibleFilters.push(ReportFilterType.TLD)
  }

  if (reportType === ReportType.DARK_WEB) {
    visibleFilters.push(ReportFilterType.DarkWebNetwork)
    visibleFilters.push(ReportFilterType.DarkWebSource)
  }

  if (isEmployeeView) {
    visibleFilters.push(ReportFilterType.DetectionReason)
    visibleFilters.push(ReportFilterType.DetectionRule)
    visibleFilters.push(ReportFilterType.Assignee)
    visibleFilters.push(ReportFilterType.NoEnforcementRequest)
  }

  if (isEmployeeView && selectedReportStatus === ReportStatus.REPORTED) {
    visibleFilters.push(ReportFilterType.IsAged)
  }

  visibleFilters.push(ReportFilterType.LinkedAlerts)

  return visibleFilters
}

export enum FilterBarStyle {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

type SpoofReportsTableFilterBarProps = {
  isOrgUnifiedView: boolean
  selectedOrgIds?: string[]
  style?: FilterBarStyle
  showSort?: boolean
  tags?: Array<{ name: string; is_internal: boolean }>
}

// TODO: refactor higher products to utilize visible filters instead of hacky if statement style
const SpoofReportsTableFilterBar = ({
  isOrgUnifiedView,
  selectedOrgIds,
  style = FilterBarStyle.HORIZONTAL,
  showSort = true,
  tags,
}: SpoofReportsTableFilterBarProps) => {
  const orgID = useOrgID()
  const orgData = getOrgData()

  const [isEmployeeView] = useIsEmployeeView()
  const [selectedReportType] = useSelectedReportType()
  const [selectedReportFilters, setSelectedReportFilters] = useSelectedReportFilters()
  const [selectedReportStatus] = useSelectedReportStatus()
  const { data: platformSubtypesData } = useGetPlatformSubtypesByProductQuery({
    variables: {
      product: PLATFORM_TYPE_DISPLAY_MAP[selectedReportType],
    },
  })
  const { data: assigneeData } = useGetUsersQuery({
    variables: {
      usersWhere: { is_internal: { _eq: true } },
    },
  })

  const { data: externalUsersData } = useGetAllExternalUsersQuery({
    variables: {
      organization_ids: isOrgUnifiedView ? [...selectedOrgIds] : [orgID],
    },
  })

  const { data: ruleData } = useGetRulesQuery({
    variables: {
      rulesWhere: {
        is_enabled: { _eq: true },
        _or: [
          {
            brand_id: { _is_null: true },
          },
          ...(isOrgUnifiedView
            ? []
            : [
                {
                  spoofing_entity: {
                    organization: {
                      id: { _eq: orgID },
                    },
                  },
                },
              ]),
        ],
      },
      orderBy: [{ created_at: Order_By.Desc }],
    },
  })

  const detectionRuleDescriptions: { [key: string]: string } = {}
  ruleData?.rules.forEach((rule) => {
    detectionRuleDescriptions[rule.id] = rule.description
  })

  const uploadersInfo = useMemo(() => {
    const combinedUsers = (assigneeData?.users || []).concat(
      externalUsersData?.users || [],
    )
    return Array.from(new Set(combinedUsers)).sort((a, b) =>
      a.email.localeCompare(b.email),
    )
  }, [assigneeData, externalUsersData])

  const alertAssignees = assigneeData?.users || []

  const sortedAssignees = getSortedAlertAssigneesWithUnassigned(alertAssignees)

  const assigneeIdToName: { [key: string]: string } = Object.fromEntries(
    sortedAssignees.map((assignee) => [assignee.id, assignee.name]),
  )

  const platformSubtypes = platformSubtypesData?.platform_subtypes || []

  const platformSubtypesIdToDisplay: { [key: string]: string } = Object.fromEntries(
    platformSubtypes.map((subtype) => [
      subtype.id,
      getPlatformSubtypeDisplayValue(subtype.name, selectedReportType),
    ]),
  )
  const visibleFilters = getFiltersForReportType(
    selectedReportType,
    isEmployeeView,
    selectedReportStatus,
  )

  const { data: brandsData } = useGetBrandNamesWithOrgQuery({
    variables: {
      where: {
        organization_id: { _in: isOrgUnifiedView ? selectedOrgIds : [orgID] },
      },
    },
  })

  const { data: tagsData } = useGetTagsQuery({
    variables: { orgIDs: isOrgUnifiedView ? [] : [orgID], includeGlobal: true },
  })

  const genReportFilters = (reportFilterType: ReportFilterType, values: string[]) => {
    return values.map((value) => genReportFilter(reportFilterType, value))
  }

  const tldValues = ['.com', '.xyz', '.us', '.io']

  const platformSubtypeFilterOptions = genReportFilters(
    ReportFilterType.PlatformSubtype,
    // entries sorted alphabetically, but with "other" at the end
    Object.entries(platformSubtypesIdToDisplay)
      .sort(([_a, a], [_b, b]) => {
        const aIsOther = a === PLATFORM_SUBTYPE_OTHER_DISPLAY_VALUE
        const bIsOther = b === PLATFORM_SUBTYPE_OTHER_DISPLAY_VALUE
        if (aIsOther !== bIsOther) {
          return aIsOther ? 1 : -1
        }
        return a.localeCompare(b)
      })
      .map(([id, _]) => id),
  )

  const assigneeFilterOptions = genReportFilters(
    ReportFilterType.Assignee,
    Object.entries(assigneeIdToName).map(([id, _]) => id),
  )

  const darkWebNetworkFilterOptions = genReportFilters(
    ReportFilterType.DarkWebNetwork,
    Object.values(DarkWebNetwork),
  )

  const darkWebSourceFilterOptions = genReportFilters(
    ReportFilterType.DarkWebSource,
    Object.values(DarkWebSource),
  )

  const spoofStatusFilterOptions = genReportFilters(
    ReportFilterType.Classification,
    Object.values(Classification).filter((type) =>
      [
        Classification.ACTIVE.toString(),
        Classification.PARKED.toString(),
        Classification.DOWN.toString(),
      ].includes(type),
    ),
  )

  const severityFilterOptions = genReportFilters(
    ReportFilterType.Severity,
    Object.values(Severity),
  )

  const sourceFilterOptions = genReportFilters(
    ReportFilterType.Source,
    Object.values(ReportSourceCategory),
  )

  const uploaderFilterOptions =
    externalUsersData && externalUsersData.users.length > 0
      ? isEmployeeView
        ? uploadersInfo &&
          uploadersInfo.map((uploader) =>
            genReportFilter(ReportFilterType.Uploader, uploader?.email),
          )
        : [
            genReportFilter(ReportFilterType.Uploader, 'Doppel Analyst'),
            ...externalUsersData.users
              .filter(
                (uploader) =>
                  uploader &&
                  !uploader.is_internal &&
                  uploader.email !== orgData[0].organization_email,
              )
              .map((uploader) =>
                genReportFilter(ReportFilterType.Uploader, uploader.email),
              ),
          ]
      : []

  const tldFilterOptions = genReportFilters(ReportFilterType.TLD, tldValues)

  const brandOptions =
    brandsData?.spoofing_entities &&
    brandsData?.spoofing_entities.map((entity) => {
      return genReportFilter(ReportFilterType.Brand, entity.id)
    })

  const tagFilterOptions =
    tags && tags.length > 0
      ? tags
          .filter((tag) => isEmployeeView || !tag.is_internal)
          .map((tag) => genReportFilter(ReportFilterType.Tag, tag.name))
      : tagsData?.tags &&
        tagsData?.tags
          .filter((tag) => isEmployeeView || !tag.is_internal)
          .map((tag) => genReportFilter(ReportFilterType.Tag, tag.name))

  const detectionReasonFilterOptions = genReportFilters(
    ReportFilterType.DetectionReason,
    FEATURES_TO_RENDER,
  )

  const detectionRuleFilterOptions = genReportFilters(
    ReportFilterType.DetectionRule,
    ruleData?.rules.map((rule) => rule.id) || [],
  )

  const isAgedFilterOptions = genReportFilters(
    ReportFilterType.IsAged,
    Object.values(AlertAgeStatus),
  )

  const filterComponentsMap = generateFilterComponentsMap({
    style,
    brandOptions,
    severityFilterOptions,
    spoofStatusFilterOptions,
    sourceFilterOptions,
    uploaderFilterOptions,
    tagFilterOptions,
    tldFilterOptions,
    platformSubtypeFilterOptions,
    darkWebNetworkFilterOptions,
    darkWebSourceFilterOptions,
    detectionReasonFilterOptions,
    detectionRuleFilterOptions,
    detectionRuleDescriptions,
    isAgedFilterOptions,
    brandsData,
    platformSubtypesIdToDisplay,
    assigneeFilterOptions,
    assigneeIdToName,
    isOrgUnifiedView,
  })

  return (
    <Flex
      align="left"
      flexDirection={style == FilterBarStyle.VERTICAL ? 'column' : 'row'}
    >
      <Flex flexWrap="wrap" rowGap={2}>
        {visibleFilters.map((filterType) => (
          <React.Fragment key={filterType}>
            {filterComponentsMap[filterType] || null}
          </React.Fragment>
        ))}
      </Flex>

      {containsFilterByType(ReportFilterType.Search, selectedReportFilters) && (
        <Flex marginRight="8px">
          <SearchFilterToken
            dropdownIcon={<IoMdSearch size="16" />}
            filterType={ReportFilterType.Search}
            selectedReportFilters={selectedReportFilters}
            setSelectedReportFilters={setSelectedReportFilters}
          />
        </Flex>
      )}

      <Spacer />

      {showSort && (
        <SortDropdown
          sortToDisplay={
            selectedReportType == ReportType.DARK_WEB
              ? darkWebSortingMechanismToDisplay
              : sortingMechanismToDisplay
          }
          supportedSortTypes={getSpoofSortTypes(
            selectedReportType,
            selectedReportStatus,
            isEmployeeView,
          )}
        />
      )}
    </Flex>
  )
}

export default SpoofReportsTableFilterBar
